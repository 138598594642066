import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Markdown from '../components/Markdown';

import Document from '../components/Document';
import Banner from '../components/Banner';
import Section from '../components/Section';
import Column from '../components/Column';
import Form from '../components/Form';
import { FormInput } from '../components/Input';
import { ErrorTypes } from '../lib/validation';

const ContactPage = ({
  data: {
    datoCmsContactPage: { header, punchline, bannerImage, content, seo },
  },
}) => {
  const { FIELD_IS_REQUIRED, EMAIL_IS_NOT_VALID } = ErrorTypes;

  return (
    <Document seo={seo}>
      <Banner bgImage={bannerImage} header={header} punchline={punchline} />
      <Section>
        <Column>
          <Markdown source={content} />
          <Form
            btnLabel="skicka meddelande"
            method="POST"
            url={`https://formcarry.com/s/${
              process.env.GATSBY_FORMCARRY_CONTACT_ID
            }`}
          >
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen fyll i ett förnamn',
              }}
              label="Förnamn"
              name="firstname"
            />
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen fyll i ett efternamn',
              }}
              label="Efternamn"
              name="lastname"
            />
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen fyll i en mailadress',
                [EMAIL_IS_NOT_VALID]: 'Vänligen fyll i en giltig e-postadress',
              }}
              label="E-post"
              name="email"
              type="email"
            />
            <FormInput
              errorMessages={{
                [FIELD_IS_REQUIRED]: 'Vänligen fyll i ett meddelande',
              }}
              label="Meddelande"
              name="massage"
              textarea
            />
          </Form>
        </Column>
      </Section>
    </Document>
  );
};

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  {
    datoCmsContactPage {
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      header
      punchline
      bannerImage {
        url
      }
      content
    }
  }
`;

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ContactPage;
