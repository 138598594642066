import React from 'react';
import PropTypes from 'prop-types';

import { breakpoints, purpleLinearGradient } from '../../css-utilities';
import { SectionFullWidth } from '../Section';

const Banner = ({ bgImage, header, punchline }) => (
  <SectionFullWidth className="skew--bottom-right" noMargin noPadding>
    <div className="banner">
      <div className="banner__content">
        <h1 className="header--underscore">{header}</h1>
        <span>{punchline}</span>
      </div>
    </div>
    <style jsx>
      {`
      .header--underscore {
        margin: ${punchline ? '0 0 30px' : 0};
      }
      span {
        display: block;
        font-family: 'Poppins';
        font-size: 25px;
        font-weight: 500;
      }
      .banner {
        grid-column: 1 / -1;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('${bgImage ? bgImage.url : 'none'}');
        background-size: cover;
        height: 455px;
        width: 100%;
        text-align: center;
        color: #fff;
      }
      @media (min-width: ${breakpoints.desktopSM}) {
        .banner:before {
          height: 20%;
          bottom: -10%;
        }
      }
      .banner:after {
        content: "";
        opacity: 0.63;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background-image: ${purpleLinearGradient};
      }
      .banner__content {
        position: relative;
        z-index: 5;
      }
    `}
    </style>
  </SectionFullWidth>
);

Banner.propTypes = {
  bgImage: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  punchline: PropTypes.string.isRequired,
};

export default Banner;
