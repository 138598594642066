import React from 'react';
import PropTypes from 'prop-types';

import { breakpoints } from '../../css-utilities';

const ErrorMessage = ({ message, active }) => (
  <span className={`wrapper ${active ? '' : 'visible'}`}>
    <span className="message">{message}</span>
    <span className="x" />
    <style jsx>
      {`
        .wrapper {
          display: block;
          margin-top: 10px;
          width: auto;
          padding: 8px;
          color: crimson;
          font-size: 12px;
          border-radius: 20px;
          border: 1px solid crimson;
          background: #ffc1c1;
          overflow: hidden;
        }

        .x {
          display: none;
        }

        @media (min-width: ${breakpoints.desktopSM}) {
          .wrapper {
            position: absolute;
            right: 8px;
            margin-top: 10px;
            max-width: 31px;
            min-width: 31px;
            height: 31px;
            z-index: 1;
            transition: 0.3s all ease-in-out;
          }

          .message {
            display: block;
            max-width: 0px;
            overflow: hidden;
            word-break: keep-all;
            white-space: nowrap;
            transition: 0.4s all ease-in-out;
          }
          .x {
            display: block;
            position: absolute;
            top: 7px;
            left: 10px;
            transition: 0.2s all ease-in-out 0.2s;
          }
          .x:before,
          .x:after {
            content: '';
            display: block;
            position: absolute;
            top: 0px;
            left: 3.5px;
            width: 2px;
            height: 16px;
            background: crimson;
          }
          .x:before {
            transform: rotate(-45deg);
          }
          .x:after {
            transform: rotate(45deg);
          }

          .wrapper:hover,
          .wrapper.visible {
            max-width: 900px;
            padding: 8px 12px;
            transition: 0.3s all ease-in-out;
          }
          .wrapper.visible {
            pointer-events: none;
          }
          .wrapper:hover .x,
          .wrapper.visible .x {
            opacity: 0;
            transition: 0.1s all ease-in-out;
          }
          .wrapper:hover .message,
          .wrapper.visible .message {
            max-width: 100%;
            width: 100%;
            text-overflow: ellipsis;
            transition: 0.3s all ease-in-out;
          }
        }
      `}
    </style>
  </span>
);

ErrorMessage.propTypes = {
  active: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
};

export default ErrorMessage;
