import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'markdown-to-jsx';

import Link from '../Link';

import { greyishBrown, bluishPurple } from '../../css-utilities';

// Wrapper for Link Component - tasked to pass `href` and `children` to Link Component
const LinkWrapper = ({ href, children }) => <Link to={href}>{children}</Link>;
LinkWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  href: PropTypes.string.isRequired,
};

const Markdown = ({ source }) => (
  <>
    <div className="markdown">
      <ReactMarkdown
        options={{
          overrides: {
            a: {
              component: LinkWrapper,
            },
          },
        }}
      >
        {source}
      </ReactMarkdown>
    </div>

    <style global jsx>
      {`
        .markdown {
          font-size: 18px;
          font-weight: 300;
          line-height: 1.56;
          color: ${greyishBrown};
        }
        .markdown h1,
        .markdown h2,
        .markdown h3,
        .markdown h4,
        .markdown h5,
        .markdown h6 {
          word-break: break-word;
          color: #000000;
        }
        .markdown ul,
        .markdown ol {
          margin: 0;
          padding-left: 20px;
          justify-content: space-evenly;
        }
        .markdown li {
          font-weight: 300;
          line-height: 1.56;
          color: ${greyishBrown};
          font-size: 18px;
        }
        .markdown a {
          color: ${bluishPurple};
          text-decoration: none;
          background-color: transparent;
        }
        .markdown a:hover,
        .markdown a:focus {
          text-decoration: underline;
        }
        .markdown a:active,
        .markdown a:visited {
          color: ${bluishPurple};
          text-decoration: none;
        }
      `}
    </style>
  </>
);

Markdown.propTypes = {
  source: PropTypes.string.isRequired,
};

export default Markdown;
