import React from 'react';
import PropTypes from 'prop-types';

import { breakpoints } from '../../css-utilities';

const Column = ({ children, width, alignContent }) => (
  <div>
    {children}

    <style jsx>
      {`
        div {
          text-align: ${alignContent && alignContent};
          width: 100%;
        }

        @media (min-width: ${breakpoints.tablet}) {
          div {
            width: ${width}%;
          }
        }
      `}
    </style>
  </div>
);

Column.defaultProps = {
  alignContent: null,
  width: '100',
};

Column.propTypes = {
  alignContent: PropTypes.string,
  children: PropTypes.node.isRequired,
  width: PropTypes.string,
};

export default Column;
