import React from 'react';
import PropTypes from 'prop-types';
// import Reaptcha from 'reaptcha';
import { Form as FinalForm } from 'react-final-form';

import { FormInput } from '../Input';
import Button from '../Button';
// import CaptchaTos from './CaptchaTos';

export const FormField = ({ field: { name, type, required } }) => {
  if (type === 'text' || type === 'email') {
    return (
      <FormInput
        className="input"
        name={name}
        placeholder={name}
        required={required}
        type={type}
      />
    );
  }
  if (type === 'checkbox') {
    return (
      <label htmlFor={name}>
        {name}
        <input id={name} name={name} required={required} type={type} />
      </label>
    );
  }
  if (type === 'textarea') {
    return (
      <textarea name={name} placeholder={`${name}...`} required={required} />
    );
  }

  return null;
};

FormField.propTypes = {
  field: PropTypes.object.isRequired,
};

const Form = ({
  btnLabel,
  url,
  // captcha,
  form,
  // captchaValidation,
  children,
  // submitForm,
  handleSubmit,
}) => (
  <FinalForm onSubmit={handleSubmit}>
    {({ handleSubmit }) => (
      <form action={url} method="POST" onSubmit={handleSubmit} ref={form}>
        {children && children}
        {/* <Reaptcha
          onVerify={submitForm}
          ref={captcha}
          sitekey="6LeHE6QUAAAAAK93oZugGnxdvtEHHDtGgwK4E7k3"
          size="invisible"
        /> */}
        <Button dark label={btnLabel} />
        {/* <CaptchaTos /> */}
      </form>
    )}
  </FinalForm>
);

Form.propTypes = {
  btnLabel: PropTypes.string.isRequired,
  // captcha: PropTypes.object.isRequired,
  // captchaValidation: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  // submitForm: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
};

export default Form;
