import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import ErrorMessage from './ErrorMessage';

import {
  brownGrey,
  bluishPurple,
  greyishBrown,
  veryLightPink,
} from '../../css-utilities';
import { composeValidators, pickValidator } from '../../lib/validation';

const Input = ({
  textarea,
  label,
  icon,
  name,
  value,
  onChange,
  meta,
  type,
  errors,
  errorMessages,
  noOffset,
  ...input
}) => (
  <>
    {textarea ? (
      <label htmlFor={name} id={name}>
        <span>{label}</span>
        <textarea
          name={name}
          onChange={onChange}
          value={value}
          {...input}
          type="text"
        />
        {icon && <i className="fas fa-search" />}
        {meta.invalid && meta.touched && (
          <ErrorMessage
            active={meta.active}
            message={errorMessages[errors[0]]}
          />
        )}
      </label>
    ) : (
      <label htmlFor={name} id={name}>
        <span>{label}</span>
        <input
          name={name}
          onChange={onChange}
          value={value}
          {...input}
          type={type}
        />
        {icon && <i className="fas fa-search" />}
        {meta.invalid && meta.touched && (
          <ErrorMessage
            active={meta.active}
            message={errorMessages[errors[0]]}
          />
        )}
      </label>
    )}
    <style jsx>
      {`
        label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          font-size: 16px;
          font-family: 'Lato';
          width: 100%;
          position: relative;
          margin-bottom: 20px;
        }

        span {
          color: ${brownGrey};
          position: absolute;
          top: ${meta.active || value !== '' ? '7px' : '17px'};
          left: 30.5px;
          font-size: ${meta.active || value !== '' ? '10px' : '16px'};
          transition: 0.2s all ease-out;
          pointer-events: none;
        }

        input,
        textarea {
          width: 100%;
          -webkit-appearance: none;
          padding: ${noOffset ? '0 30px' : '10px 30px 0'};
          color: ${greyishBrown};
          height: 50px;
          border-radius: 25px;
          border: 1px solid;
          border-color: ${meta.active ? bluishPurple : veryLightPink};
        }

        textarea {
          height: 250px;
          padding: 20px 30px;
          resize: none;
        }

        i {
          position: absolute;
          right: 0px;
          top: calc(50% - 16px / 2);
          right: 23px;
          color: ${bluishPurple};
        }
      `}
    </style>
  </>
);

Input.defaultProps = {
  errorMessages: null,
  errors: [],
  icon: false,
  label: '',
  meta: {},
  noOffset: false,
  textarea: false,
  type: 'text',
};

Input.propTypes = {
  errorMessages: PropTypes.object,
  errors: PropTypes.array,
  icon: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  name: PropTypes.string.isRequired,
  noOffset: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  textarea: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
};

export default Input;

// FormInput = Input Component + React Final Form
export const FormInput = ({ label, name, textarea, errorMessages, type }) => {
  let validate;
  if (errorMessages) {
    let validators = Object.keys(errorMessages).map(message =>
      pickValidator(message)
    );
    validate = composeValidators(validators);
  } else {
    validate = null;
  }

  return (
    <Field name={name} type={type} validate={validate}>
      {({ input, meta }) => (
        <Input
          errorMessages={errorMessages}
          errors={meta.error}
          label={label}
          meta={meta}
          name={name}
          textarea={textarea}
          type={type}
          {...input}
        />
      )}
    </Field>
  );
};

FormInput.defaultProps = {
  errorMessages: null,
  label: '',
  textarea: false,
  type: 'text',
};

FormInput.propTypes = {
  errorMessages: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  textarea: PropTypes.bool,
  type: PropTypes.string,
};
