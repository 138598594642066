export const ErrorTypes = {
  FIELD_IS_REQUIRED: 'FIELD_IS_REQUIRED',
  EMAIL_IS_NOT_VALID: 'EMAIL_IS_NOT_VALID',
  PHONE_IS_NOT_VALID: 'PHONE_IS_NOT_VALID',
  ORG_NUMBER_IS_NOT_VALID: 'ORG_NUMBER_IS_NOT_VALID',
  SHOULD_BE_NUMBER: 'SHOULD_BE_NUMBER',
  CHECKBOX_IS_REQUIRED: 'CHECKBOX_IS_REQUIRED',
};

const regEx = {
  mail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^[0-9-() +]+$/,
  orgNumber: /^[0-9]{6}-[0-9]{4}$/,
  number: /^[0-9 ]+$/,
};

const fieldIsRequired = value => (value ? null : ErrorTypes.FIELD_IS_REQUIRED);

const checkboxIsRequired = value =>
  value ? null : ErrorTypes.CHECKBOX_IS_REQUIRED;

const emailIsNotValid = value => {
  const res = regEx.mail.test(String(value).toLowerCase());
  return res === true ? null : ErrorTypes.EMAIL_IS_NOT_VALID;
};

const phoneIsNotValid = value => {
  const res = regEx.phone.test(String(value).toLowerCase());
  return res === true ? null : ErrorTypes.PHONE_IS_NOT_VALID;
};

const orgNumberIsNotValid = value => {
  const res = regEx.orgNumber.test(String(value).toLowerCase());
  return res === true ? null : ErrorTypes.ORG_NUMBER_IS_NOT_VALID;
};

const fieldShouldBeNumber = value => {
  const res = regEx.number.test(String(value).toLowerCase());
  return res === true ? null : ErrorTypes.SHOULD_BE_NUMBER;
};

export const pickValidator = message => {
  switch (message) {
    case ErrorTypes.FIELD_IS_REQUIRED:
      return fieldIsRequired;
    case ErrorTypes.SHOULD_BE_NUMBER:
      return fieldShouldBeNumber;
    case ErrorTypes.EMAIL_IS_NOT_VALID:
      return emailIsNotValid;
    case ErrorTypes.PHONE_IS_NOT_VALID:
      return phoneIsNotValid;
    case ErrorTypes.CHECKBOX_IS_REQUIRED:
      return checkboxIsRequired;
    case ErrorTypes.ORG_NUMBER_IS_NOT_VALID:
      return orgNumberIsNotValid;
  }
};

export const composeValidators = validators => value => {
  const errorArr = validators.reduce((errors, validator) => {
    const error = validator(value);
    if (error !== null) {
      return [...errors, error];
    }
    return errors;
  }, []);
  return errorArr.length !== 0 ? errorArr : undefined;
};
