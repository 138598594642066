import React, { useRef, useCallback } from 'react';
import PropTypes from 'prop-types';

import Form from './Form';

const FormWrapper = ({ btnLabel, url, children }) => {
  let captcha = useRef();
  const form = useRef();

  const handleSubmit = useCallback(() => {
    form.current.submit();
  });

  // const captchaValidation = useCallback(() => {
  //   // valitade fields or something
  //   captcha.current.execute();
  // });

  return (
    <Form
      btnLabel={btnLabel}
      captcha={captcha}
      // captchaValidation={captchaValidation}
      form={form}
      handleSubmit={handleSubmit}
      submitForm={handleSubmit}
      url={url}
    >
      {children && children}
    </Form>
  );
};

FormWrapper.propTypes = {
  btnLabel: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  url: PropTypes.string.isRequired,
};

export default FormWrapper;
